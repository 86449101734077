@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@200;600&display=swap');

*{
  font-family: 'Spartan', sans-serif;
  color: white;
  /* background-color: #18182D; */
  margin: 0;
  padding: 0;
}
*::before{
  border-style: none;
}
.bgImg{
  background:  linear-gradient(rgba(0, 0, 0, 0.4),  rgba(0, 0, 0, 0.4)) , url("./assets/Headerbg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 40rem; 
}
.FAQbg{
  background-image: url("https://res.cloudinary.com/qonvex-tech/image/upload/v1655889923/Qonvey%20LandingPage/FAQbg_nguz6o.png");
  background-position: cover;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
}
.minibg{
  background-image: url("https://res.cloudinary.com/qonvex-tech/image/upload/v1653431298/Qonvey%20LandingPage/Home_qnhfto.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  margin-top: -5rem;
}
.bgfooter{
    background-image: url("https://res.cloudinary.com/qonvex-tech/image/upload/v1639927120/Qonvey%20LandingPage/Frame_5703_b3jzcg.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
}
.seventhSectionBg{
  background:  linear-gradient(rgba(0, 0, 0, 0.4),  rgba(0, 0, 0, 0.4)) , url("https://res.cloudinary.com/qonvex-tech/image/upload/v1653432984/Qonvey%20LandingPage/Frame_5702_ecxdel.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 40rem;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 576px) { 
    .minibg{
        height: auto;
        background-size: auto;
    }
}

@media (max-width: 425px) { 
  .minibg{
    height: auto;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
  .minibg{
    height: auto;
  }
 }

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
  .minibg{
    height: auto;
  }
 }

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {  }
