p.p2 {
    margin: 0.0px 0.0px 8.0px 36.0px;
    text-align: justify;
    font: 11.0px Candara;
    color: #002060;
    -webkit-text-stroke: #002060;
    min-height: 13.0px
}

p.p3 {
    margin: 0.0px 0.0px 8.0px 36.0px;
    text-align: justify;
    font: 11.0px Candara;
    color: #002060;
    -webkit-text-stroke: #002060
}

p.p4 {
    margin: 0.0px 0.0px 8.0px 72.0px;
    text-align: justify;
    font: 11.0px Candara;
    color: #002060;
    -webkit-text-stroke: #002060;
    min-height: 13.0px
}

p.p5 {
    margin: 0.0px 0.0px 8.0px 18.0px;
    text-align: justify;
    text-indent: 18.0px;
    font: 11.0px Candara;
    color: #002060;
    -webkit-text-stroke: #002060
}

p.p6 {
    margin: 0.0px 0.0px 8.0px 0.0px;
    text-align: justify;
    text-indent: 36.0px;
    font: 11.0px Candara;
    color: #002060;
    -webkit-text-stroke: #002060
}

li.li1 {
    margin: 0.0px 0.0px 8.0px 0.0px;
    text-align: justify;
    font: 11.0px Candara;
    color: #002060;
    -webkit-text-stroke: #002060
}

span.s1 {
    -webkit-text-stroke: 0px #000000
}

span.s2 {
    font-kerning: none
}

ol.ol1 {
    list-style-type: upper-alpha
}

ol.ol2 {
    list-style-type: lower-roman
}